import { request } from '@/utils/request'

/* 授权接口 */
export const authApi = {
  checkToken: () => request.get('global/token/check', undefined, { withoutCheck: true }), // 检查 token 是否过期
  loginByAccount: params => request.post('login', params), // 账号登录
  loginByMobile: params => request.post('login/mobile', params), // 手机号登录
  resetPassword: params => request.post('password/reset', params), // 重置密码
  resetPayPassword: params => request.post('payPassword/reset', params), // 重置密码
  logout: () => request.post('/my/logout'), // 登出
  getUserInfo: () => request.get('my/info'), // 获取用户信息
  getCaptcha: params => request.post('/global/captcha/mobile', params), // 获取手机验证码
}

