import EmptyLayout from '@/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const paymentRoutes: CustomRouteRecordRaw = {
  path: 'payment',
  name: 'payment',
  component: EmptyLayout,
  meta: {
    title: '资产中心',
    iconfont: 'icon-currency_exchange_black_24dp',
    requiredPermission: 'PAYMENT_VIEW',
  },
  children: [
    // 支付账户
    {
      path: 'payment-account',
      name: 'paymentAccount',
      component: () => import('@/views/payment/payment-account.vue'),
      meta: {
        title: '账户概览',
      },
    },
    {
      path: 'payment-receipt',
      name: 'paymentReceipt',
      component: () => import('@/views/payment/receipt-list.vue'),
      meta: {
        title: '电子回单',
      },
    },
  ],
}
