/* 暂未使用 */

/* 类型注释 */
export interface Rule {
  required?: boolean;
  message?: string;
  len?: number;
  max?: number;
  min?: number;
  pattern?: string;
  type?: string;
  validator?: Fn;
}

export class Validator {
  model: Recordable

  rules: Record<string, Rule[]>

  constructor(model, rules) {
    this.model = model
    this.rules = rules
  }

  /* 效验器 尝试抽离公共代码 */
  validator(rules, value) {
    const result: string[] = [] // 保存校验信息
    rules.forEach(rule => {
      let msg = ''
      const {
        len = 0, // 字段长度
        max = 0, // 最大长度
        min = 0, // 最小长度
        message = '', // 校验文案
        pattern = '', // 正则表达式校验
        type = 'string', // 类型
        required = false, // 是否必填
        validator // 自定义函数
      } = rule


      if (required) {
        if (Array.isArray(value) && !value.length) {
          msg = message || '请输入'
        } else if (!value) {
          msg = message || '请输入'
        }
      }
      if (pattern) {

        if (!pattern.test(value)) {
          msg = message || '请输入正确的值'
        }
      }
      if (validator && typeof validator === 'function') {
        msg = validator(value, rules)
      }
      if (msg) {
        result.push(msg)
      }
    })
    return result
  }


  /* 效验 尝试抽离公共代码 */
  validate(): string[] {
    let messages: any = []
    const ruleKeys = Object.keys(this.rules)
    ruleKeys.forEach(key => {
      const arr: any = this.validator(this.rules[key], this.model[key])
      if (arr.length) {
        messages = [...messages, ...arr]
      }
    })

    return messages
  }
}
