<!-- maxCount属性会失效 -->

<template>
  <div>
    <a-upload
      v-bind="bindAttrs"
      :before-upload="handleBeforeUpload"
      @change="handleChange"
      @preview="handlePreview"
    >
      <div v-if="filePaths.length < maxNum">
        <template v-if="props.acceptType === 'img'">
          <upload-outlined />
          <div class="ant-upload-text">
            上传
          </div>
        </template>
        <template v-else-if="props.acceptType === 'file'">
          <div
            :style="{width: `${$props.width}px`}"
            class="p-4 text-blue-400 border border-blue-400 border-dashed flex flex-col justify-center items-center text-center cursor-pointer"
          >
            <upload-outlined />
            <span>{{ `点击或直接将文件拖拽到此处进行上传，支持格式${getAccept.split(',').join('、')}` }}</span>
          </div>
        </template>
        <template v-else>
          <a-button>
            <upload-outlined /> 上传
          </a-button>
        </template>
      </div>
    </a-upload>
    <div class="text-gray-400 leading-8">
      {{ getDesc }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRaw, unref, useAttrs } from 'vue'
import { useMessage } from '@/hooks/message'
import { UploadOutlined } from '@ant-design/icons-vue'
import { useEnv } from '@/hooks/env'
import { useUserStore } from '@/store'
import { previewFromUrl } from '@/utils/file'
import { pick, omit, isArray } from 'lodash-es'
import { UploadFile, UploadProps, Upload } from 'ant-design-vue'
import { uploadProps } from './props'
import { getFileName } from '@/utils/qs'
import imageCompression from 'browser-image-compression'


const attrs = useAttrs()
type EmitEvents = {
  (e: 'update:value', filePaths: Array<string>): void
}

const emits = defineEmits<EmitEvents>()
const props = defineProps(uploadProps)

// 文件路径数组
const filePaths = ref<string[]>([])

// upload 组件值，设置该值，使得 upload 组件为受控组件。上传的状态及图片的显示，由 files 控制
const files = ref<UploadProps['fileList']>([])
onMounted(() => {
  const initValues = isArray(props.value) ? props.value : [props.value]
  initValues.forEach(fileUrl => {
    const fileName = getFileName(fileUrl)
    files.value?.push({
      uid: fileName,
      name: fileName,
      status: 'done',
      url: fileUrl,
    })
  })
  filePaths.value = initValues.map(fileUrL => new URL(fileUrL).pathname)
  if (filePaths.value.length) {
    emits('update:value', filePaths.value)
  }
})

/* 获取类型描述 */
const getDesc = computed(() => {
  if (props.desc || attrs.accept) {
    return props.desc
  }

  return `支持格式：${getAccept.value.split(',').join('、')}；大小限制：不能超过${props.maxSize}M`
})

// 允许上传的文件类型
const getAccept = computed(() => {
  let acceptArr

  if (attrs.accept) {
    return attrs.accept
  }

  if (props.acceptType === 'img') {
    acceptArr = ['jpg', 'jpeg', 'png']
  }
  if (props.acceptType === 'zip') {
    acceptArr = ['zip']
  }
  if (props.acceptType === 'doc') {
    acceptArr = ['doc', 'docx']
  }
  if (props.acceptType === 'file') {
    acceptArr = ['xls', 'xlsx', 'pdf']
  }

  return acceptArr.map(i => (i.startsWith('.') ? i : `.${i}`)).join(',')
})

// 获取上传接口调用的请求头
const getHeaders = computed(() => ({
  ...pick(attrs, 'headers'),
  Authorization: `Bearer ${useUserStore().token}`,
}))

const getParams = computed(() => ({
  ...pick(attrs, 'data'),
  type: props.acceptType,
}))

const bindAttrs = computed(() => ({
  fileList: unref(files),
  action: useEnv.uploadApiUrl,
  headers: unref(getHeaders),
  data: unref(getParams),
  accept: unref(getAccept),
  ...(props.acceptType === 'img' ? { 'list-type': 'picture-card' } : {}),
  ...omit(attrs, ['headers', 'data']),
}))

// 上传前拦截
async function handleBeforeUpload(file: File) {
  const { size, name } = file
  const { maxSize, maxNum } = props

  if (maxSize && maxSize * 1024 * 1024 < size) {
    useMessage.error(`只能上传不超过${maxSize}M的文件`)
    
    return Upload.LIST_IGNORE
  }

  if (maxNum && maxNum <= filePaths.value.length) {
    useMessage.error(`最多上传${maxNum}个文件`)
    return Upload.LIST_IGNORE
  }

  const acceptArr = unref(getAccept).split(',')
    .map(i => (i.startsWith('.') ? i.slice(1) : i))
  if (acceptArr.length > 0 && !new RegExp(`\\.(${acceptArr.join('|')})$`, 'i').test(name)) {
    useMessage.error(`只能上传${acceptArr.join(',')}格式的文件`)
    return Upload.LIST_IGNORE
  }
  if (props.acceptType === 'img') {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      initialQuality: 0.6
    }
    const compressedFile = await imageCompression(file, options)
    return Promise.resolve(compressedFile)
  }
  return true
}

// 上传完成，数据整理
function handleChange(e) {
  const { file, fileList } = e as { file: UploadFile, fileList: UploadFile[] }
  
  if (file.status !== 'done' && file.status !== 'removed' && file.status !== 'uploading') {
    return
  }

  files.value = fileList // 设置为受控组件，需要将 uploading 状态的 file 同步到 files 中，使的上传功能继续执行，才能触发 done 等上传结束事件

  filePaths.value = toRaw(fileList)
    .filter(f => f.status === 'done')
    .map(f => {
      if (f.response) { // 新上传的文件
        return f.response.data.path
      } else { // 数据回填的文件
        return new URL(f.url!).pathname
      }
    })
  emits('update:value', filePaths.value)
}

// 预览功能，解决 ant-design 默认新开页面，打开 base64 数据造成的 chrome 安全警告问题，Not allowed to navigate top frame to data URL:
function handlePreview(file: UploadFile) {
  
  if (file.response) { // 新上传的文件
    previewFromUrl(file.response.data.url)
  } else { // 数据回填的文件
    previewFromUrl(file.url!)
  }
}
</script>
