<!-- 模态框弹窗 -->
<!-- 对 ant-design 进行了简单的二次封装，实现全屏等效果 -->
<!-- 在 form 组件中使用的外层弹窗，均来自于此，可以理解为代替 ant-design model 的存在 -->

<style lang="less">
.ant-modal-close-x {
  padding-right: 10px;
  padding-left: 0;
  width: 30px;
}

.ant-modal-title {
  position: relative;
}

.ant-modal-title .full-screen:hover {
  opacity: 1;
}

.ant-modal-title .full-screen {
  position: absolute;
  top: -14px;
  right: 10px;
  display: inline-block;
  padding: 14px 0;
  opacity: 0.6;
  cursor: pointer;
}

.full-modal {
  .ant-modal {
    top: 0;
    margin: 0;
    padding-bottom: 0;
    max-width: 100%;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }

  .ant-modal-body {
    flex: 1;
  }
}
</style>

<template>
  <a-modal
    v-bind="bindAttrs"
    v-model:visible="visible"
    :confirm-loading="props.loading"
  >
    <template
      v-for="slot in Object.keys(omit(slots, ['title', 'default']))"
      #[slot]="data"
    >
      <slot
        :name="slot"
        v-bind="data || {}"
      />
    </template>
    <template #title>
      <div class="flex items-center justify-start">
        <h3>{{ title }}</h3>
        <span
          v-if="props.warning"
          class="ml-2 text-xs font-normal text-red-600"
        >提示：{{ props.warning }}</span>
      </div>
      <div class=" full-screen">
        <a-tooltip
          v-if="state.fullScreen"
          title="还原"
          placement="bottom"
        >
          <fullscreen-exit-outlined
            class=" p-1"
            @click.stop="toggleFullScreen"
          />
        </a-tooltip>
        <a-tooltip
          v-else
          title="最大化"
          placement="bottom"
        >
          <fullscreen-outlined
            class=" p-1"
            @click.stop="toggleFullScreen"
          />
        </a-tooltip>
      </div>
    </template>
    <div
      ref="wrapperRef"
      :style="bodyStyle"
    >
      <slot />
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
import {
  computed,
  CSSProperties,
  nextTick,
  reactive,
  ref,
  unref,
  useAttrs,
} from 'vue'
import { BasicModalProps, basicModalProps } from './prop'
import { getHtmlElementHeight } from '@/utils/dom'
import { useMutationObserver } from '@vueuse/core'
import { useWindowSizeFn } from '@/hooks/window-size-fn'
import { omit } from 'lodash-es'

const props = defineProps(basicModalProps)
const attrs = useAttrs()
const slots = useSlots()

type EmitEvents = {
  (e:'update:visible', visible:boolean):void
}
const emits = defineEmits<EmitEvents>()
const wrapperRef = ref<HTMLElement | null>(null)
const visible = ref(true)
const state = reactive({
  fullScreen: props.fullScreen,
  height: 380,
  wrapClassName: '',
})

// 设置模态框可以滚动
function setDrag() {
  type CustomHTMLElement = HTMLDivElement & {
    left: number;
    top: number;
    setCapture: Fn;
    releaseCapture: Fn;
  }
  const modal = document.getElementsByClassName('ant-modal')[0] as CustomHTMLElement
  const header = document.getElementsByClassName('ant-modal-header')[0] as CustomHTMLElement

  // 重置弹窗位置
  modal.style.left = '0'
  modal.style.top = '100px'

  // 鼠标变成可移动的指示
  header.style.cursor = 'move'

  header.onmousedown = e => {
    let startX = e.clientX
    let startY = e.clientY

    // 通过直接设置回调，来解决添加多个回调问题，将上一次设置的回调覆盖
    document.onmousemove = event => {
      const endX = event.clientX
      const endY = event.clientY
      modal.style.left = `${parseInt(modal.style.left || '0') + endX - startX}px`
      modal.style.top = `${modal.offsetTop + endY - startY}px`
      startX = endX
      startY = endY
    }

    document.onmouseup = () => {
      document.onmousemove = null
      document.onmouseup = null
      header.releaseCapture && header.releaseCapture()
    }

    // setCapture 让一个元素可以捕获所有的鼠标事件，避免鼠标移出监听范围，导致无法触发响应函数
    header.setCapture && header.setCapture()
  }
}

// 监听外部控制，是否全屏
watchEffect(() => {
  state.wrapClassName = state.fullScreen ? 'full-modal' : ''
  setModalHeight()
})

// 监听外部控制。是否显示
watchEffect(() => {
  visible.value = props.visible
})

// 监听内部 visible 值的变化，向外部更新
watchEffect(() => {
  visible.value && nextTick(setDrag)
  emits('update:visible', visible.value)
  // 关闭弹窗，重置配置
  if (!visible.value) {
    state.fullScreen = false
  }
})

useWindowSizeFn(setModalHeight)
useMutationObserver(wrapperRef, setModalHeight, {
  subtree: true,
  attributes: true,
})

const bindAttrs = computed((): BasicModalProps => ({
  zIndex: 1001,
  ...attrs,
  ...omit(props, ['title', 'visible']),
  ...state,
  ...(state.fullScreen ? { width: '100%' } : {}),
}))

const bodyStyle = computed((): CSSProperties => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  paddingTop: '18px',
  paddingBottom: '18px',
  minHeight: '100px',
  [state.fullScreen ? 'height' : 'maxHeight']: `${state.height}px`,
}))

function toggleFullScreen() {
  state.fullScreen = !state.fullScreen
  nextTick(() => {
    const modal = document.getElementsByClassName('ant-modal')[0] as HTMLDivElement
    modal.style.left = '0'
    if (state.fullScreen) {
      modal.style.top = '0'
    } else {
      modal.style.top = '100px'
    }
  })
}

async function setModalHeight() {
  if (!visible.value) {
    return
  }

  const wrapperDom = unref(wrapperRef)
  if (!wrapperDom) {
    return
  }

  const bodyDom = wrapperDom.parentElement
  if (!bodyDom) {
    return
  }
  bodyDom.style.paddingTop = '0'
  bodyDom.style.paddingBottom = '0'

  const modalDom = bodyDom.parentElement?.parentElement
  if (!modalDom) {
    return
  }

  await nextTick()

  // 出现滚动条，添加paddingRight
  if (wrapperDom.scrollHeight > wrapperDom.offsetHeight) {
    wrapperDom.style.paddingRight = '10px'
  }
  const marginTop = Number.parseInt(getComputedStyle(modalDom).top)
  const headerHeight = getHtmlElementHeight('.ant-modal .ant-modal-header')
  const footerHeight = getHtmlElementHeight('.ant-modal .ant-modal-footer')
  state.height = window.innerHeight - marginTop * 2 - headerHeight - footerHeight
}
</script>
