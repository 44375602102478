import EmptyLayout from '@/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const personalEndRoutes: CustomRouteRecordRaw = {
  path: 'personal-end',
  name: 'personalEnd',
  component: EmptyLayout,
  meta: {
    title: '个人端管理',
    icon: 'user-outlined',
  },
  children: [
    // 广告管理
    {
      path: 'ad-list',
      name: 'adList',
      component: () => import('@/views/personal-end/ad/ad-list.vue'),
      meta: {
        title: '广告管理',
        requiredPermission: 'AD_VIEW',
      },
    },
    // 职位列表
    {
      path: 'job-list',
      name: 'jobList',
      component: () => import('@/views/personal-end/job/job-list.vue'),
      meta: {
        title: '职位列表',
        requiredPermission: 'JOB_MODEL',
      },
    },

    /* 编辑职位 */
    {
      path: 'job-form',
      name: 'jobForm',
      component: () => import('@/views/personal-end/job/job-form.vue'),
      meta: {
        title: '职位信息',
        requiredPermission: 'JOB_EDIT',
        hideInMenu: true,
        parentNameForBreadcrumb: 'jobList'
      },
      beforeEnter: to => {
        if (to.query.jobId) {
          to.meta.title = '更新职位'
        } else {
          to.meta.title = '新增职位'
        }
      }
    },
    {
      path: 'job-tag-list',
      name: 'jobTagList',
      component: () => import('@/views/personal-end/job-tag/job-tag-list.vue'),
      meta: {
        title: '职位标签',
        requiredPermission: 'JOB_TAG_VIEW',
      },
    },

    /* 用户列表 */
    {
      path: 'followed-worker-list',
      name: 'followedWorkerList',
      component: () => import('@/views/personal-end/followed-worker/followed-worker-list.vue'),
      meta: {
        title: '用户列表',
        requiredPermission: 'USER_VIEW',
      },
    },
    // 用户详情
    {
      path: 'followed-worker-detail',
      name: 'followedWorkerDetail',
      component: () => import('@/views/personal-end/followed-worker/followed-worker-detail.vue'),
      meta: {
        title: '用户详情',
        hideInMenu: true,
        requiredPermission: 'USER_VIEW',
        parentNameForBreadcrumb: 'followedWorkerList'
      },
    },
    // 用户详情
    {
      path: 'batch-add-followed-worker',
      name: 'batchAddFollowedWorker',
      component: () => import('@/views/personal-end/followed-worker/batch-add-followed-worker.vue'),
      meta: {
        title: '用户详情',
        hideInMenu: true,
        requiredPermission: 'USER_EDIT',
        // parentNameForBreadcrumb: 'followedWorkerList'
      },
    },
  ],
}
