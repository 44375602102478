<!-- 页面 -->
<template>
  <a-modal
    v-bind="modalProps"
    :width="620"
    ok-text="检查并导入"
    :ok-button-props="{
      disabled: Boolean(excelImportDetail.errorList.length && excelImportDetail.andDataDetail),
      type: 'primary',
    }"
    @ok="checkExcel"
    @cancel="cancel"
  >
    <a-upload
      :before-upload="handleBeforeUpload"
      :file-list="excelImportDetail.fileList"
      :max-count="1"
      @change="handleChange"
      @remove="init"
    >
      <div>
        <div
          :style="{width: `572px`}"
          class="p-4 text-blue-400 border border-blue-400 border-dashed"
        >
          <upload-outlined />
          {{ `点击或直接将文件拖拽到此处进行上传，支持格式.xlsx、.xls` }}
        </div>
      </div>
    </a-upload>

    <template v-if="excelImportDetail.errorList.length">
      <div
        class="mt-2 p-4 bg-gray-200 leading-7 text-red-400 overflow-y-auto"
        style="max-height: 116px;"
      >
        <div v-for="record in excelImportDetail.errorList">
          {{ record.error }}
        </div>
      </div>
      <div class="mt-2 text-right">
        <span
          class="text-blue-400 underline cursor-pointer"
          @click="exportErrorList"
        >下载错误数据</span>
      </div>
    </template>
    <div>
      <a-divider class="text-gray-400" />
      <div class="flex justify-between">
        上传前确认：
      </div>
      <div class="leading-6">
        <div>1、仅支持excel文件（格式为.xlsx和.xls），数据必须放在第一个sheet；</div>
        <div>2、上传表格支持范围：不超过500行，且大小不超过5M的文件</div>
        <div>
          3、下载导入 <span
            class="text-blue-400 underline cursor-pointer"
            @click="downloadFile(fileUrl, {}, templateName, 'xlsx')"
          >
            模板
          </span>，按照格式填写文件
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script lang="tsx" setup>
import { useMessage } from '@/hooks/message'
import { BasicModalProps } from '@/components/modal/prop'
import { PropType } from 'vue'
import excelImportModel from '@/hooks/global-excel-import'
// import ParseExcel from '@/components/form/components/business-components/parse-excel/parse-excel.vue'
import { downloadFile } from '@/utils/file'
import { aoaToSheetXlsx, getExcelOriginCell, getSheetHeaderAndData2 } from '@/utils/xlsx'
import { UploadFile, Upload } from 'ant-design-vue'
import { get } from 'lodash-es'
import { deleteEmptyProp } from '@/utils/object'

/* data 数据 */
const props = defineProps({
  modalProps: {
    type: Object as PropType<BasicModalProps>,
    default: () => ({})
  },
  checkApi: {
    type: Function,
    default: () => {},
  },
  importApi: {
    type: Function,
    default: () => {},
  },
  transformCheckRecords: {
    type: Function,
    default: records => records,
  },

  /* 文本键映射 */
  /*
    例:
    const textKeyMap = {
      '*姓名': 'realname',
      '*手机号': 'mobile',
      '*身份证号': 'idcard',
      用工单位: 'companyName',
      员工状态: 'status',
      入职日期: 'enteredAt',
    }
   */
  textKeyMap: {
    type: Object,
    default: () => ({})
  },
  fileUrl: {
    type: String,
    default: '',
  },
  templateName: {
    type: String,
    default: '',
  },
  rules: {
    type: Object,
    default: () => ({})
  }

})
const excelImportDetail = reactive<any>({
  errorList: [],
  fileList: [],
  andDataDetail: null,
})
const maxSize = 5

/* logics 逻辑 */
/* methods 方法 */
// 上传前拦截
function handleBeforeUpload(file: File) {
  const { size, name } = file

  if (maxSize && maxSize * 1024 * 1024 < size) {
    useMessage.error(`只能上传不超过${maxSize}M的文件`)
    return Upload.LIST_IGNORE
  }

  const acceptArr = ['xlsx', 'xls']
    .map(i => (i.startsWith('.') ? i.slice(1) : i))
  if (acceptArr.length > 0 && !new RegExp(`\\.(${acceptArr.join('|')})$`, 'i').test(name)) {
    useMessage.error(`只能上传${acceptArr.join(',')}格式的文件`)
    return Upload.LIST_IGNORE
  }
  return false
}

// 上传完成，数据整理
async function handleChange(e) {

  const { file, fileList } = e as { file: UploadFile, fileList: UploadFile[] }
  excelImportDetail.fileList = fileList
  if (file.status !== 'removed') {
    const sheetList = await getExcelOriginCell(file as any) // 获取 excel 表所有 sheet 所有单元格数据
    // const andDataDetail = getSheetHeaderAndData2(sheetList[0], props.textKeyMap)
    const andDataDetail = getSheetHeaderAndData2(sheetList[0], props.textKeyMap)

    excelImportDetail.andDataDetail = andDataDetail

    /* 效验 */

    andDataDetail.dataSource.map((record, index) => {
      for (const key in props.rules) {
        const messages = validator(props.rules[key], get(record, key))
        if (messages.length) {
          record.error = `第${index + 1 + andDataDetail.headerRowNum}行：${messages.join('、')}`
          andDataDetail.dataRows[index].push(messages.join('、'))
        }
      }
      return record
    })
    excelImportDetail.errorList = andDataDetail.dataSource.filter(record => record.error)

  }
}
function cancel() {
  excelImportModel.visible = false
  init()
}

/* 效验数据 */
function validator(rules, value) {
  const result: string[] = [] // 保存校验信息
  rules.forEach(rule => {
    let msg = ''
    const {
      len = 0, // 字段长度
      max = 0, // 最大长度
      min = 0, // 最小长度
      message = '', // 校验文案
      pattern = '', // 正则表达式校验
      type = 'string', // 类型
      required = false, // 是否必填
      validator // 自定义函数
    } = rule

    if (required && !value) {
      msg = message || '请输入'
    }
    if (pattern) {

      if (!pattern.test(value)) {
        msg = message || '请输入正确的值'
      }
    }
    if (validator && typeof validator === 'function') {
      msg = validator(value, rules)
    }
    if (msg) {
      result.push(msg)
    }
  })
  return result
}

/* 导出错误数据 */
function exportErrorList() {
  aoaToSheetXlsx({
    header: undefined,
    data: excelImportDetail.andDataDetail?.dataRows,
    filename: '错误数据.xlsx',
  })
}

/* 初始化 */
function init() {
  excelImportDetail.errorList = []
  excelImportDetail.fileList = []
  excelImportDetail.andDataDetail = null
}

/* 暂时适用导入预支名单检测 */
async function checkExcel() {

  const records = await props.checkApi?.(deleteEmptyProp({
    list: excelImportDetail.andDataDetail?.dataSource,
  }))
  excelImportDetail.errorList = records?.map((record, index) => {
    if (record.error) {
      record.error = `第${index + 1 + excelImportDetail.andDataDetail?.headerRowNum}行：${record.error}`

      excelImportDetail.andDataDetail.dataRows[index].push(record.error)
    }
    return record
  }).filter(record => record.error)

  if (excelImportDetail.errorList.length) {
    // excelImportDetail.errorList = await props.transformCheckRecords(records) // 暂不需要
    useMessage.error('含有错误信息, 请导出检查后重新导入')
  } else {
    await props.importApi?.(deleteEmptyProp({
      list: excelImportDetail.andDataDetail?.dataSource,
    }))
    useMessage.success('操作成功')
    cancel()
  }
}
</script>