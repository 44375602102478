<template>
  <div>
    <div ref="instanceElRef" />
  </div>
</template>

<script setup lang="ts">
import { reactive, onMounted, ref, PropType, watch, useAttrs } from 'vue'
import Editor from 'wangeditor'

import WangEditor from 'wangeditor'
import { useUserStore } from '@/store/modules/user'
import { useEnv } from '@/hooks/env'

const userStore = useUserStore()
const instanceElRef = ref<HTMLElement>()
const emits = defineEmits(['change', 'update:value', 'blur'])
const attrs = useAttrs()
const props = defineProps({
  value: String as PropType<string>,
  height: {
    type: Number,
    defualt: 180
  }
})
let editor: Editor

onMounted(setup)

watch(
  () => props.value,
  val => {
    if (!val) {
      editor.txt.clear()
    }
  },
)

function setup() {
  editor = new WangEditor(instanceElRef.value)
  editor.config.menus = [
    'head',
    'bold',
    // 'fontSize',
    // 'fontName',
    // 'italic',
    // 'underline',
    // 'strikeThrough',
    'indent',
    'lineHeight',
    // 'foreColor',
    // 'backColor',
    // 'link',
    // 'list',
    'justify',
    // 'quote',
    'emoticon',
    'image',
    // 'table',
    // 'code',
    'splitLine',
    'undo',
    'redo',
  ]
  editor.config.placeholder = (attrs.placeholder ?? '请输入内容') as unknown as string
  editor.config.excludeMenus = ['todo', 'video']
  editor.config.menuTooltipPosition = 'down'
  editor.config.uploadImgServer = useEnv.uploadApiUrl
  editor.config.fontSizes = {
    'x-small': {
      name: '10px',
      value: '1'
    },
  }
  editor.config.uploadImgHeaders = {
    Authorization: `Bearer ${userStore.token}`,
  }
  editor.config.zIndex = 1
  editor.config.uploadFileName = 'file'
  editor.config.uploadImgParams = {
    type: 'editor',
    fileable_field: 'editor',
  }
  editor.config.onchange = function(value) {
    emits('change', value)
    emits('update:value', value)
  }
  editor.config.onchangeTimeout = 500
  // editor.config.height = props.height!
  editor.create()
  if (props.value) {
    editor.txt.html(props.value)
  }

}
</script>
