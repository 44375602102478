import EmptyLayout from '@/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const workerRoutes: CustomRouteRecordRaw = {
  path: 'worker',
  name: 'worker',
  component: EmptyLayout,
  meta: {
    title: '员工管理',
    iconfont: 'icon-icon_member_default',
    // icon: 'team-outlined',
    requiredPermission: 'EMPLOYEE_VIEW'
  },
  children: [
    // 员工列表
    {
      path: 'worker-list',
      name: 'workerList',
      component: () => import('@/views/worker/worker-list.vue'),
      meta: {
        requiredPermission: 'EMPLOYEE_ON_JOB_VIEW',
        title: '在职员工',
      },
    },
    // 员工详情
    {
      path: 'worker-detail/:id',
      name: 'workerDetail',
      component: () => import('@/views/worker/worker-detail.vue'),
      meta: {
        title: '员工详情',
        hideInMenu: true,
      },
    },
    // 用工创建
    {
      path: 'batch-add-worker',
      name: 'batchAddWorker',
      component: () => import('@/views/worker/batch-add-worker.vue'),
      meta: {
        title: '批量导入员工',
        hideInMenu: true,
      },
    },
    // 用工创建
    {
      path: 'add-worker-step1',
      name: 'addWorkerStep1',
      component: () => import('@/views/worker/add-worker-step1.vue'),
      meta: {
        title: '新增员工',
        hideInMenu: true,
      },
    },
    {
      path: 'add-worker-step2',
      name: 'addWorkerStep2',
      component: () => import('@/views/worker/add-worker-step2.vue'),
      meta: {
        title: '新增员工',
        hideInMenu: true,
      },
    },
    // 员工入离职
    {
      path: 'worker-employ',
      name: 'workerEmploy',
      component: () => import('@/views/worker/worker-employ.vue'),
      meta: {
        title: '入离职管理',
        // requiredPermission: ''
      },
    },
    // 员工黑名单
    {
      path: 'worker-blacklist',
      name: 'workerBlacklist',
      component: () => import('@/views/worker/worker-blacklist.vue'),
      meta: {
        requiredPermission: 'BLACKLIST_VIEW',
        title: '黑名单管理',
      },
    },
    // 员工标签
    {
      path: 'worker-tag-list',
      name: 'workerTagList',
      component: () => import('@/views/worker/worker-tag-list.vue'),
      meta: {
        requiredPermission: 'EMPLOYEE_TAG_VIEW',
        title: '员工标签',
      },
    },
  ],
}
